import {useEffect, useState} from "react";
import {Amplify} from "aws-amplify";
import {Authenticator, Text, ThemeProvider as AWSTheme, translations, useTheme, View,} from "@aws-amplify/ui-react";
import {getAwsExports} from "./assets/aws-exports";
import {fetchAuthSession, fetchUserAttributes} from "aws-amplify/auth";
import {Hub, I18n} from "aws-amplify/utils";
import "@aws-amplify/ui-react/styles.css";
import {ThemeProvider} from "@mui/material/styles";

import {createBrowserRouter, RouterProvider} from "react-router-dom";

import {CssBaseline, MenuItem, Select} from "@mui/material";

import {FORM_FIELDS} from "./constants/form-fields";
import {THEME} from "./constants/theme";

import UserContext from "./contexts/UserContexts";

import Root from "./routes/Root";

import ProfilePage from "./pages/profile";
import BusinessList from "./pages/business-list";
import ErrorPage from "./pages/error-page/ErrorPage";
import CustomerList from "./pages/customer-list";
import Dashboard from "./pages/dashboard/Dashboard";
import AdminstratorsList from "./pages/administrator-list";
import EditBusiness from "./pages/edit-business";
import AddBusiness from "./pages/add-business";
import EditCustomer from "./pages/edit-customer";
import AddCustomer from "./pages/add-customer";
import ProductList from "./pages/product-list";
import AddProduct from "./pages/add-product";
import EditProduct from "./pages/edit-product";
import AddAdmin from "./pages/add-admin";
import EditAdmin from "./pages/edit-admin";
import Whitelist from "./pages/whitelist";
import WhitelistAdd from "./pages/whitelist-add";

import LoginComponents from "./components/login";
import {UserProvider} from "./contexts/UserContext";
import {LanguageProvider} from "./contexts/LanguageContext";
import LanguageSelect from "./pages/LanguageSelect";
import EnglishTranslations from "./assets/translationJSON/portalTranslationsEnglish.json";
import FrenchTranslations from "./assets/translationJSON/portalTranslationsFrench.json";
import ConfirmInvite from "./pages/invite-member/ConfirmInvite";
import Reports from "./pages/Reports";

Amplify.configure(getAwsExports());

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState("");
  const [userGroup, setUserGroup] = useState("");
  const [jwtToken, setJwtToken] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [languageSelected, setLanguageSelected] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    let langPref = params.get("langPref");

    if (langPref) {
      langPref = langPref.replace(/"/g, ""); // Remove any surrounding quotes
      if (langPref === "en_US" || langPref === "fr_CA") {
        localStorage.setItem("language", langPref);
        return langPref;
      }
    } else {
      const storedLanguage = localStorage.getItem("language");
      return storedLanguage === "en_US" || storedLanguage === "fr_CA"
        ? storedLanguage
        : null;
    }
  });
  const [fetchedTranslations, setFetchedTranslations] = useState(false);
  const [loginComponents, setLoginComponents] = useState(LoginComponents);
  const queryParams = new URLSearchParams(window.location.search);
  const loginStateParam = queryParams.get("landing");
  let loginState;
  if (loginStateParam) {
    switch (loginStateParam?.toLowerCase()) {
      case "signin": loginState = "signIn"; break;
      case "signup": loginState = "signUp"; break;
      case "forgotpassword": loginState = "forgotPassword"; break;
      default: loginState = "signIn"; break;
    }
  }

  async function getTranslationFields() {
    function getLanguageFromLocalStorage() {
      try {
        const storedLanguage = localStorage.getItem("language");
        return storedLanguage || "en_US";
      } catch (error) {
        console.error("Error accessing local storage:", error);
        return "en_US";
      }
    }

    function getAmplifyLanguage() {
      return getLanguageFromLocalStorage() === "fr_CA" ? "fr" : "en";
    }

    function getTranslationFromFile() {
      if (languageSelected === "fr_CA") return FrenchTranslations;
      else return EnglishTranslations;
    }

    try {
      let languageData = getTranslationFromFile();

      I18n.putVocabularies(translations);
      I18n.setLanguage(getAmplifyLanguage());

      FORM_FIELDS.signUp.username.label = languageData.userNameLabelSignUp;
      FORM_FIELDS.signUp.username.placeholder = languageData.userNamePlaceHolderSignUp;
      FORM_FIELDS.signUp.given_name.label = languageData.firstNameLabel;
      FORM_FIELDS.signUp.given_name.placeholder = languageData.firstNamePlaceHolder;
      FORM_FIELDS.signUp.email.label = languageData.emailLabel;
      FORM_FIELDS.signUp.email.placeholder = languageData.emailPlaceHolder;
      FORM_FIELDS.signUp.family_name.label = languageData.lastNameLabel;
      FORM_FIELDS.signUp.family_name.placeholder = languageData.lastNamePlaceHolder;

      FORM_FIELDS.signIn.username.label = languageData.userNameLabelSignIn;
      FORM_FIELDS.signIn.username.placeholder = languageData.userNamePlaceHolderSignIn;

      // I18n.putVocabularies({
      //   [getAmplifyLanguage()]: {
      //     Username: languageData.userNameLabelSignIn,
      //     "Enter your Username": languageData.userNamePlaceHolderSignIn,
      //     "Enter your username or email": languageData.userNamePlaceHolderSignIn,
      //     "Phone Number": languageData.phoneNumberLabel,
      //     "Enter your Phone Number": languageData.phoneNumberPlaceHolder,
      //     "Given Name": languageData.firstNameLabel,
      //     "Enter your Given Name": languageData.firstNamePlaceHolder,
      //     "Family Name": languageData.lastNameLabel,
      //     "Enter your Family Name": languageData.lastNamePlaceHolder,
      //     Email: languageData.emailLabel,
      //     "Enter your Email": languageData.emailPlaceHolder,
      //     Password: languageData.passwordLabelSignIn,
      //     "Enter your Password": languageData.passwordPlaceHolderSignIn,
      //     "Confirm Password": languageData.confirmPasswordLabelSignUp,
      //     "Please confirm your Password":
      //       languageData.confirmPasswordPlaceHolderSignUp,
      //     "Sign in": languageData.signInLabelButton,
      //     "Sign In": languageData.signInLabelTab,
      //     "Forgot your password?": languageData.forgotPasswordSignIn,
      //     "Create Account": languageData.signUpLabelTab,
      //   },
      // });

      setLoginComponents((prevComponents) => ({
        ...prevComponents,
        Footer() {
          const { tokens } = useTheme();
          return (
            <>
              <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                  &copy; {languageData.footer_rights}
                </Text>
              </View>
            </>
          );
        },
      }));

      setFetchedTranslations(true);
    } catch (error) {
      console.error("Error fetching translation fields:", error);
    }
  }

  useEffect(() => {
    if (!languageSelected) return;

    getTranslationFields();
  }, [languageSelected]);

  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          setIsLoggedIn(true);
          break;
        case "signedOut":
          setIsLoggedIn(false);
          break;
        default:
          break;
      }
    });
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const userAttributes = JSON.parse(
          localStorage.getItem("userAttributes")
        );
        const idToken = localStorage.getItem("idToken");
        const userGroup = localStorage.getItem("userGroup");

        if (userAttributes && idToken && userGroup) {
          setUserData(userAttributes);
          setJwtToken(idToken);
          setUserGroup(userGroup);
        } else {
          await getUserData();
          await getTokenData();
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (isLoggedIn) {
      loadData();
    }

    getTokenData().finally(() => setIsLoading(false));
    getUserData().finally(() => setIsLoading(false));
  }, [isLoggedIn]);

  const getUserData = async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      localStorage.setItem("userAttributes", JSON.stringify(userAttributes));
      setUserData(userAttributes);
    } catch (error) {}
  };

  const getTokenData = async () => {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      localStorage.setItem("idToken", idToken?.toString());
      setJwtToken(idToken?.toString());
      if (accessToken) {
        const userGroup =
          accessToken.payload["cognito:groups"] &&
          accessToken.payload["cognito:groups"][0];
        localStorage.setItem("userGroup", userGroup);
        setUserGroup(userGroup);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const userGroupPrefix = userGroup ? userGroup.split("_")[0] : undefined;

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        { index: true, element: <Dashboard /> },
        {
          path: "businesses",
          children: [
            { element: <BusinessList />, index: true },
            { path: ":businessId/add", element: <AddBusiness /> },
            { path: "add", element: <AddBusiness /> },
            {
              path: ":businessId/adminstrators",
              element: <AdminstratorsList />,
            },
            { path: ":businessId/adminstrators/add", element: <AddAdmin /> },
            {
              path: ":businessId/adminstrators/:customerId",
              element: <EditAdmin />,
            },
            { path: ":businessId", element: <EditBusiness /> },
            { path: ":businessId/customers", element: <CustomerList /> },
            { path: ":businessId/customers/add", element: <AddCustomer /> },
            {
              path: ":businessId/customers/:customerId",
              element: <EditCustomer />,
            },
          ],
        },
        {
          path: "products",
          children: [
            { element: <ProductList />, index: true },
            { path: "add", element: <AddProduct /> },
            { path: ":productId", element: <EditProduct /> },
          ],
        },
        {
          path: "/whitelist",
          children: [
            { element: <Whitelist />, index: true },
            { path: "add", element: <WhitelistAdd /> },
          ],
        },
        { path: "reports", element: <Reports /> },
        { path: "profile", element: <ProfilePage /> },
        { path: "invite", element: <ConfirmInvite /> },
      ],
    },
  ]);

  return (
    <AWSTheme theme={THEME}>
      <ThemeProvider theme={THEME}>
        <CssBaseline />
        {fetchedTranslations ? (
          <div style={{ position: "relative" }}>
            <LanguageProvider>
              <Authenticator
                initialState={loginState}
                formFields={FORM_FIELDS}
                loginMechanisms={["username"]}
                components={loginComponents}
              >
                <UserProvider jwtToken={jwtToken}>
                  <UserContext.Provider
                    value={{
                      userData,
                      userGroup,
                      jwtToken,
                      userGroupPrefix,
                      isLoading,
                    }}
                  >
                    <RouterProvider router={router} />
                  </UserContext.Provider>
                </UserProvider>
              </Authenticator>
              {!jwtToken ? (
                <Select
                  value={languageSelected}
                  onChange={(e) => {
                    localStorage.setItem("language", e.target.value);
                    window.location.reload();
                  }}
                  sx={{
                    position: "absolute",
                    right: 16,
                    top: 16,
                    width: "70px",
                    height: "50px",
                  }}
                >
                  <MenuItem value="en_US">EN</MenuItem>
                  <MenuItem value="fr_CA">FR</MenuItem>
                </Select>
              ) : (
                <></>
              )}
            </LanguageProvider>
          </div>
        ) : (
          <>
            {!languageSelected && (
              <LanguageSelect setLanguageSelected={setLanguageSelected} />
            )}
          </>
        )}
      </ThemeProvider>
    </AWSTheme>
  );
}

export default App;
