import React, {useContext} from "react";
import styles from "./Footer.module.css";
import {Link} from "react-router-dom";

import LanguageContext from "../../contexts/LanguageContext";
import {useAdminPortalTranslation} from "../../api/translationApi";

const Footer = () => {
  const { adminPortalTranslation } = useAdminPortalTranslation();
  const { language } = useContext(LanguageContext);

  const currentYear = new Date().getFullYear();

  const policyURL = {
    english: "https://scamnetic.com/privacy/",
    french: "https://scamnetic.com/fr-ca/confidentialite/",
  };
  const termsURL = {
    english: "https://scamnetic.com/terms-of-service/",
    french: "https://scamnetic.com/fr-ca/modalites-dutilisation/",
  };

  return (
    <footer className={styles.footer}>
      <p>© {currentYear} Scamnetic</p>
      <Link
        className={styles.footerLink}
        to={language === "fr_CA" ? policyURL.french : policyURL.english}
        target="_blank"
        rel="noreferrer"
        aria-label="Privacy Policy"
      >
        {adminPortalTranslation?.footer_1 || "Privacy Policy"}
      </Link>
      <a
        className={styles.footerLink}
        href={language === "fr_CA" ? termsURL.french : termsURL.english}
        target="_blank"
        rel="noreferrer"
        aria-label="Terms of Service"
      >
        {adminPortalTranslation?.footer_2 || "Terms of Service"}
      </a>
    </footer>
  );
};

export default Footer;
