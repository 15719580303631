import {Box} from "@mui/material";
import React from "react";

const YouTubeEmbed = ({ width, src }) => {
  const aspectRatio = (9 / 16) * 100;

  return (
    <Box
      sx={{
        position: "relative",
        width: width,
        paddingBottom: `${aspectRatio}%`,
        height: 0,
        overflow: "hidden",
      }}
    >
      <Box
        component="iframe"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={src}
        title="KnowScam"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        loading="lazy"
      />
    </Box>
  );
};

export default YouTubeEmbed;
