import {InputLabel, MenuItem, Select} from "@mui/material";
import {Controller} from "react-hook-form";

const ProductSelect = ({control, name, disabled = false, products, translation}) => {

    const formInputStyles = {
        bgcolor: "#F1F3F4",
        borderRadius: 1,
        "& .MuiFormHelperText-root": {
            color: "red",
            margin: 0,
            padding: 1,
            backgroundColor: "#fff",
        },
    };

    return (
        <>
            <InputLabel htmlFor="product">
                {translation.business_product}
            </InputLabel>

            <Controller
                name={name}
                control={control}
                render={({field}) => (
                    <Select
                        {...field}
                        fullWidth
                        sx={formInputStyles}
                        disabled={disabled}
                    >
                        {products && products.map((product, index) => (
                            <MenuItem key={product.id} value={product?.id}>{product.name || product.productName}</MenuItem>
                        ))}

                    </Select>
                )}
            />
        </>
    )
}

export default ProductSelect;