import {useEffect, useState} from "react";
import {fetchBusinessInfo} from "../utils/api"; // Adjust the import path as needed

const useGetBusiness = (businessId = null, jwtToken) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      if (!businessId || !jwtToken) return;

      setLoading(true);
      setError(null);

      try {
        let result;
        // if (businessId) {
        //   result = await fetchBusinessInfo(businessId, jwtToken);
        // } else {
        //   result = await fetchAllBusinesses(jwtToken);
        // }
        result = await fetchBusinessInfo(businessId, jwtToken);
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [businessId, jwtToken]);

  return { data, loading, error };
};

export default useGetBusiness;
