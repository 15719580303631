const baseURL = process.env["REACT_APP_PORTAL_API_URL"];

export const fetchProducts = async (jwtToken) => {
  const url = `${baseURL}/products`;
  const options = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  };
  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      throw new Error("Failed to fetch products");
    }

    if (response.ok) {
      const productList = await response.json();
      if (productList && productList.data) {
        const filteredData = productList.data.filter((item) => item !== null);
        return { products: filteredData, error: null };
      }
    }
  } catch (error) {
    console.error(error);
    return { products: null, error: error.message };
  }
};

export const fetchMembersList = async (
  businessId,
  jwtToken,
  nextToken,
  type
) => {
  const url = `${baseURL}/businesses/${businessId}/members?type=${type}`;
  const response = await fetch(
    `${url}${nextToken ? `&nextToken=${nextToken}` : ""}`,
    {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
  );

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error("Failed to fetch administrators");
  }
};

export const fetchBusinessInfo = async (businessId, jwtToken) => {
  const url = `${baseURL}/businesses/${businessId}`;
  const options = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  };
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch business info:", error);
    throw error;
  }
};

export const fetchContentful = async (query) => {
  const response = await fetch(
    `https://graphql.contentful.com/content/v1/spaces/ejwdnvauirkd/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env["REACT_APP_CONTENTFUL_TOKEN"]}`,
      },
      body: JSON.stringify({ query }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to fetch content");
  }

  const { data, errors } = await response.json();

  if (errors) {
    console.error(errors);
    throw new Error("Failed to fetch content");
  }

  return data;
};

export const fetchProfileDetails = async (jwtToken) => {
  const url = `${baseURL}/members/details`;
  const response = await fetch(
    url,

    {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
  );

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error("Failed to fetch profile details");
  }
};

export const postLanguagePreference = async (
  jwtToken,
  businessId,
  memberLanguage
) => {
  const url = `${baseURL}/businesses/${businessId}/members`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    },
    body: JSON.stringify({ memberLanguage }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

export const fetchFaq = async (language) => {
  const cdaUrl = process.env["REACT_APP_CONTENTFUL_CMA_URL"];
  const spaceId = process.env["REACT_APP_CONTENTFUL_SPACE_ID"];
  const environmentId = process.env["REACT_APP_CONTENTFUL_ENVIRONMENT_ID"];
  const cdaToken = process.env["REACT_APP_CONTENTFUL_CDA_TOKEN"];

  const formattedLanguage = language.replace("_", "-");

  const url = `${cdaUrl}/spaces/${spaceId}/environments/${environmentId}/entries?access_token=${cdaToken}&content_type=faq&locale=${formattedLanguage}`;

  const response = await fetch(url, {
    method: "GET",
  });

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    throw new Error("Failed to fetch FAQ");
  }
};

export class NoProductsException extends Error {

}

export class ServiceException extends Error {

}

export const fetchLicenseDetails = async (jwtToken, userName) => {
  const url = `${baseURL}/members/${userName}/license`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else if (response.status === 404) {
    throw new NoProductsException("There are no active products for this customer");
  } else {
    throw new ServiceException("Failed to fetch license details");
  }
};

export const inviteMember = async (jwtToken, memberDetails) => {
  const url = `${baseURL}/members/invite`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwtToken,
    },
    body: JSON.stringify(memberDetails),
  });

  if (response.status === 200 || response.status === 201) {
    const data = await response.json();
    return data;
  } else {
    return { statusCode: response.status };
  }
};

export const cancelInvite = async (jwtToken, memberId) => {
  const url = `${baseURL}/members/invited/${memberId}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });

  if (
    response.status === 200 ||
    response.status === 202 ||
    response.status === 204
  ) {
    const data = await response.json();
    return data;
  } else {
    return { statusCode: response.status };
  }
};


export const getBusinesses = async (jwtToken) => {
  const url = `${baseURL}/businesses`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch businesses");
  }

  return response.json();
};
