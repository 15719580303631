import React from "react";
import Typography from "@mui/material/Typography";

function HeaderTitle({ children }) {
  return (
    <Typography
      component="h1"
      color="primary"
      fontWeight={400}
      sx={{ fontSize: "30px", color: "#020D0F" }}
      gutterBottom
    >
      {children}
    </Typography>
  );
}

export default HeaderTitle;
