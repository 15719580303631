import React from "react";
import {Box, Button} from "@mui/material";
import YouTubeEmbed from "../../components/youtube-embed.js/YouTubeEmbed";

const AcceptanceVideo = ({ onSkip }) => {
  return (
    <Box
      marginTop={4}
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <YouTubeEmbed
        width="560px"
        height="315px"
        src="https://www.youtube.com/embed/9l3Wo4K_zBw?si=6T0FXt3YD-emPJFY"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          mt: 2,
        }}
      >
        <Button variant="contained" color="secondary" onClick={onSkip}>
          Skip
        </Button>
      </Box>
    </Box>
  );
};

export default AcceptanceVideo;
