import React, {useContext} from "react";
import {Grid} from "@mui/material";
import UserContext from "../../contexts/UserContexts";
import {useAdminPortalTranslation} from "../../api/translationApi";
import useGetBusiness from "../../hooks/useGetBusiness";
import {useParams} from "react-router-dom";
import BusinessTabs from "../../components/business-tabs";
import PageHeader from "../../components/page-header";

const EditBusiness = () => {
  const { businessId } = useParams();
  const { jwtToken } = useContext(UserContext);
  const { data: businessData } = useGetBusiness(businessId, jwtToken);
  const { userGroupPrefix } = useContext(UserContext);
  const isAdmin = userGroupPrefix === "Admin";
  const isBusinessAdmin = userGroupPrefix === "BusinessAdmin";
  const { loading } = useContext(UserContext);

  const {
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationIsLoading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error with Portal Admin Translations...</div>;
  }

  return (
    <>
      <Grid container spacing={4} padding={1}>
        <Grid item xs={12}>
          <PageHeader title={businessData?.data?.name} subtitle={"ID: " + businessData?.data?.id} />
        </Grid>
      </Grid>

      {(isAdmin || isBusinessAdmin) && (
          <Grid item xs={12}>
            <BusinessTabs businessData={businessData} />
          </Grid>
      )}
    </>
  );
};

export default EditBusiness;
