import {useState} from "react";
import {deleteLicense} from "../api/productLicense";

const useDeleteLicense = (jwtToken) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const handleDeleteLicense = async (productId) => {
    setIsDeleting(true);
    setDeleteError(null);

    try {
      await deleteLicense(jwtToken, productId);
      setIsDeleting(false);
    } catch (error) {
      setDeleteError(error);
      setIsDeleting(false);
    }
  };

  return { isDeleting, deleteError, handleDeleteLicense };
};

export default useDeleteLicense;
