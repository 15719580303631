import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {Alert, Box, Button, Grid, Snackbar, TextField, Typography,} from "@mui/material";
import classes from "./BusinessForm.module.css";
import UserContext from "../../contexts/UserContexts";
import UploadImage from "../upload-image";
import LanguageContext from "../../contexts/LanguageContext";
import useInternalTranslation from "../../hooks/useInternalTranslation";
import ProductList from "../../pages/product-list";
import FormFieldV2 from "../form-field/FormFieldV2";
import ProductSelect from "../product-form/ProductSelect";
import {getProducts} from "../../api/api-products";
import {createBusiness, getBusiness, updateBusiness,} from "../../api/api-business";
import PageHeader from "../page-header";
import PhoneFieldV2 from "../phone-field/PhoneFieldV2";

const BusinessForm = ({method, isRetail, translation}) => {
    const {businessId} = useParams();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const {jwtToken, userGroupPrefix} = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [previewURL, setPreviewURL] = useState("");
    const [availableProducts, setAvailableProducts] = useState([]);
    const [pageEditMode] = useState(method === "PUT");
    const {control, handleSubmit, setValue, formState: {errors}} = useForm({
        defaultValues: {
            business: {
                name: "",
                status: "",
                primaryContact: {
                    id: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                },
                subdomainName: "",
                productId: ''
            },
        },
    });

  const [business, setBusiness] = useState();
  const { t } = useInternalTranslation();
  const isBusAdmin = userGroupPrefix === "BusinessAdmin";
  const isAdmin = userGroupPrefix === "Admin";
  const { language } = useContext(LanguageContext);
  const businessLogoString =
    language === "en_US" ? "Business Logo" : "Logo de l'entreprise";

  useEffect(() => {
    if (business?.subdomainName) {
      fetchLogo();
    }
  }, [business?.subdomainName]);

  const handleNavigate = (businessId) => {
    window.location.href = `/businesses/${businessId}`;
  };

  const fetchData = async () => {
    if (isAdmin) {
      getProducts({ idToken: jwtToken, isAdmin }).then((data) =>
        setAvailableProducts(data)
      );
    } else if (isBusAdmin) {
      setAvailableProducts(getBusinessProducts(business));
    }

    if (pageEditMode) {
      await fetchBusiness(businessId);
    }
  };

  useEffect(() => {
    fetchData();
  }, [businessId, isAdmin]);

  function getBusinessProducts(businessData) {
    let productList = businessData?.productDetails;
    if (typeof productList === "string") {
      productList = JSON.parse(productList);
      if (!Array.isArray(productList)) {
        productList = [productList];
      }
    }
    return productList;
  }

  async function imageUrlExists(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        return resolve(url);
      };
      img.onerror = () => {
        return resolve(false);
      };
      img.src = url;
    });
  }

  async function fetchLogo() {
    let url = `${
      process.env.REACT_APP_ASSETS_URL
    }/businesses/logos/${business?.subdomainName?.toLowerCase()}`;

    const imagePreviews = await Promise.allSettled([
      imageUrlExists(`${url}.png?ver=${new Date().toISOString()}`),
      imageUrlExists(`${url}.jpg?ver=${new Date().toISOString()}`),
    ]);

    for (let imagePreview of imagePreviews) {
      if (!!imagePreview.value) {
        setPreviewURL(imagePreview.value);
      }
    }
  }

  const fetchBusiness = async (businessId) => {
    const business = await getBusiness(jwtToken, businessId);
    setBusiness(business);
    setValue("business.name", business?.name);
    setValue("business.primaryContact.id", business?.primaryContact.id);
    setValue(
      "business.primaryContact.firstName",
      business?.primaryContact.firstName
    );
    setValue(
      "business.primaryContact.lastName",
      business?.primaryContact.lastName
    );
    setValue("business.primaryContact.email", business?.primaryContact.email);
    setValue(
      "business.primaryContact.phoneNumber",
      business?.primaryContact.phoneNumber
    );
    setValue("business.subdomainName", business?.subdomainName);
    setValue("business.status", business?.status);
    let productList = getBusinessProducts(business);
    setValue("business.productId", productList[0]?.id);
  };

    const onSubmit = async (data) => {
        try {
            let response;
            if (pageEditMode) {
                const updatedBusiness = {...data.business, ...{status: "Active"}}
                response = await updateBusiness(jwtToken, updatedBusiness, businessId);
                if (response && response?.data) {
                    setOpen(true);
                }
            } else {
                const createdBusiness = {...data.business, ...{status: "Active"}}
                response = await createBusiness(jwtToken, createdBusiness);
                if (response?.data) {
                    setOpen(true);
                    // Give the user an idea that the save completed before we direct them to the edit with tabs
                    setTimeout(() => {
                        handleNavigate(response?.data?.business?.id);
                    }, 600)
                }
            }

      if (!response) {
        setErrorMessage(`${t("Failed to submit form")}: ${response}`);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
      setErrorMessage(t("Failed to submit form"));
    }
  };

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      setErrorMessage(null);
    }, 250);
  }

  const action = (
    <>
      &nbsp; &nbsp; &nbsp;
      <Button
        color="secondary"
        size="small"
        sx={{
          color: "white",
        }}
        component={Link}
        to={`/businesses`}
      >
        <u>{t(`Back to List`)}</u>
      </Button>
    </>
  );

  const snackBar = () => {
    return (
      <Snackbar
        open={open || !!errorMessage}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={!errorMessage ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {!!errorMessage && errorMessage}
          {!errorMessage &&
            pageEditMode &&
            translation["success_update_business"]}
          {!errorMessage &&
            !pageEditMode &&
            translation["success_create_business"]}
          {!errorMessage && action}
        </Alert>
      </Snackbar>
    );
  };

  function showPreviewURL() {
    return previewURL ? (
      <Box display="flex" flexDirection="row">
        <img
          alt={"Preview URL"}
          src={previewURL}
          style={{
            maxWidth: "125px",
            maxHeight: "125px",
          }}
        />
      </Box>
    ) : (
      <></>
    );
  }

    function showProductName() {
        const products = getBusinessProducts(business);
        return (
            <TextField
                value={(products && products[0]?.productName) || ""}
                disabled={true}
                fullWidth
            />
        )
    }

  function navigateToCancel(e) {
    if (isAdmin) {
      navigate("/businesses");
    } else {
      window.location.reload();
    }
  }

  return (
    <>
      {snackBar()}

      <FormProvider>
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Grid container columnSpacing={8}>
            <Grid item xs={12}>
              {!pageEditMode ? (
                <PageHeader
                  styles={{ marginLeft: "-12px", marginBottom: "22px" }}
                  title={translation.add_business_section_header}
                />
              ) : (
                <></>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <FormFieldV2
                  label={t("Business Name")}
                  control={control}
                  error={errors?.business?.name}
                  helperText={errors?.business?.name?.message}
                  name={"business.name"}
                  rules={{
                    required: t("Business Name is required"),
                    minLength: {
                      value: 2,
                      message: t(
                        "Business Name should be at least 2 characters"
                      ),
                    },
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {/*Here*/}
                  <FormFieldV2
                    label={t("First Name")}
                    control={control}
                    error={errors?.business?.primaryContact?.firstName}
                    helperText={
                      errors?.business?.primaryContact?.firstName?.message
                    }
                    name={"business.primaryContact.firstName"}
                    rules={{
                      required: t("First Name is required"),
                      minLength: {
                        value: 1,
                        message: t(
                          "First Name should be at least 1 characters"
                        ),
                      },
                    }}
                  />

                  <FormFieldV2
                    label={t("Last Name")}
                    control={control}
                    error={errors?.business?.primaryContact?.lastName}
                    helperText={
                      errors?.business?.primaryContact?.lastName?.message
                    }
                    name={"business.primaryContact.lastName"}
                    rules={{
                      required: t("Last Name is required"),
                      minLength: {
                        value: 1,
                        message: t("Last Name should be at least 1 characters"),
                      },
                    }}
                  />
                </div>

                                {/*<FormFieldV2 label={t("Phone")}*/}
                                {/*             control={control}*/}
                                {/*             error={errors?.business?.primaryContact?.phoneNumber}*/}
                                {/*             helperText={errors?.business?.primaryContact?.phoneNumber?.message}*/}
                                {/*             name={"business.primaryContact.phoneNumber"} rules={{*/}
                                {/*    required: t("Phone is required"),*/}
                                {/*    validate: (value) => {*/}
                                {/*        return (*/}
                                {/*            isValidPhoneNumber(value) ||*/}
                                {/*            t("Please enter a valid phone number")*/}
                                {/*        );*/}
                                {/*    },*/}
                                {/*}}/>*/}

                                <div style={{ "padding" : "6px", "width": "100%"}}>
                                <PhoneFieldV2 label={t("Phone")}
                                             control={control}
                                             error={errors?.business?.primaryContact?.phoneNumber}
                                             // helperText={errors?.business?.primaryContact?.phoneNumber?.message}
                                             name={"business.primaryContact.phoneNumber"} rules={{
                                    required: t("Phone is required"),
                                }}/>
                                </div>

                <FormFieldV2
                  label={t("Email")}
                  control={control}
                  error={errors?.business?.primaryContact?.email}
                  helperText={errors?.business?.primaryContact?.email?.message}
                  name={"business.primaryContact.email"}
                  rules={{
                    required: t("Email is required"),
                    validate: (value) => {
                      const emailPattern =
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                      return (
                        emailPattern.test(value) ||
                        t("Email has improper format")
                      );
                    },
                  }}
                />

                {!isRetail && isAdmin && (
                  <div
                    style={{
                      marginLeft: "8px",
                      marginTop: "8px",
                      width: "100%",
                    }}
                  >
                    <ProductSelect
                      translation={translation}
                      products={availableProducts}
                      control={control}
                      name={"business.productId"}
                    />
                  </div>
                )}

                {!isAdmin && (
                  <div style={{ padding: "6px", width: "100%" }}>
                    <div>{translation?.productName}</div>
                    {showProductName()}
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <FormFieldV2
                  label={translation.sub_domain_name}
                  disabled={isBusAdmin}
                  control={control}
                  error={errors?.business?.subdomainName}
                  helperText={errors?.business?.subdomainName?.message}
                  name="business.subdomainName"
                  rules={{
                    validate: (value) => {
                      const subdomainNamePattern =
                        /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)?$/;
                      return (
                        subdomainNamePattern.test(value) ||
                        t("Sub domain has improper format")
                      );
                    },
                  }}
                />

                {business?.subdomainName && (
                  <Grid item xs={12}>
                    <>
                      <Box display="flex" alignItems="center">
                        <Typography variant="h5" xs={{ mt: 2 }}>
                          {businessLogoString}
                        </Typography>
                      </Box>

                      <Box display="flex" flexDirection="column" gap={3}>
                        {showPreviewURL()}
                        {!isBusAdmin && (
                          <>
                            <UploadImage
                              setPreview={setPreviewURL}
                              translation={translation}
                              disabled={!isBusAdmin}
                            />
                            <Typography variant="body1">
                              {t(
                                "Please upload a PNG or JPEG with a maximum size of 800x800 pixels"
                              )}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isRetail && (
              <Grid item xs={12} marginTop={6}>
                <ProductList productList={getBusinessProducts(business)} />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={3}>
              <Button
                color="tertiary"
                component={Link}
                type="button"
                onClick={(e) => navigateToCancel(e)}
                variant="contained"
                size="large"
                fullWidth
                sx={{ my: 4, borderRadius: 0 }}
              >
                {translation.button_cancel}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                fullWidth
                sx={{ my: 4, borderRadius: 0 }}
                type="submit"
              >
                {translation.button_save}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default BusinessForm;
