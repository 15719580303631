import {Controller} from "react-hook-form";
import {InputLabel} from "@mui/material";
import PhoneInput from "react-phone-number-input/input";
import {isValidPhoneNumber} from "react-phone-number-input";
import styles from "./PhoneField.module.css";
import useInternalTranslation from "../../hooks/useInternalTranslation";
import React from "react";

const PhoneFieldV2 = ({
                        label,
                        name,
                        control,
                        error,
                        value,
                    }) => {
    const { t } = useInternalTranslation();

    return (
        <>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <Controller
                name={name}
                control={control}
                rules={{
                    required: t("Phone number is required"),
                    validate: (value) => {
                        return (
                            isValidPhoneNumber(value) ||
                            t("Please enter a valid phone number")
                        );
                    },
                }}
                render={({ field }) => (
                    <>
                        <PhoneInput
                            id={name}
                            className={styles.phoneInput}
                            required
                            {...field}
                            style={{
                                border: error?.message
                                    ? "1px solid #d32f2f"
                                    : "1px solid rgba(0, 0, 0, 0.25)",
                            }}
                            // onChange={(e) => {
                            //     field.onChange(e);
                            //     if (onChange) {
                            //         onChange(e);
                            //     }
                            // }}
                            value={value !== undefined ? value : field.value}
                        />
                        {error?.message && (
                            <p
                                style={{
                                    fontSize: "13px",
                                    color: "#d32f2f",
                                }}
                            >
                                {error?.message}
                            </p>
                        )}
                    </>
                    // <div style={{ padding: "6px", width: "100%" }}>
                    //     <div>{label}</div>
                    //     <TextField
                    //         {...field}
                    //         disabled={disabled}
                    //         fullWidth
                    //         sx={formInputStyles}
                    //         error={!!error}
                    //         helperText={helperText}
                    //     />
                    // </div>
                )}
            />
        </>
    );
};

export default PhoneFieldV2;
