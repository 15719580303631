import React, {useEffect, useState} from "react";
import {Alert, Box, Button, Grid, Snackbar, Typography} from "@mui/material";
import {withAuthenticator} from "@aws-amplify/ui-react";
import classes from "./CustomerDetails.module.css";
import PhoneField from "../../../phone-field/PhoneField";
import FormField from "../../../form-field";
import {Controller, useForm} from "react-hook-form";
import UploadImage from "../../../upload-image";
import {fetchProfileDetails} from "../../../../utils/api";
import useInternalTranslation from "../../../../hooks/useInternalTranslation";

const ProfileForm = ({ translation }) => {
  const { handleSubmit, control } = useForm();
  const [isChanged, setIsChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(undefined);
  const jwtToken = localStorage.getItem("idToken");
  const [data, setData] = useState(undefined);
  const { t } = useInternalTranslation();

  useEffect(() => {
    async function getProfileData() {
      let profileData = await fetchProfileDetails(jwtToken);
      profileData = profileData.data;

      setData(profileData);
    }
    getProfileData();
  }, []);

  const handleInputChange = () => {
    setIsChanged(true);
  };

  const onSubmit = async (formData) => {
    const submitData = {
      ...formData,
      memberLanguage: localStorage.getItem("language") || "en_US",
    };

    const url = `${process.env["REACT_APP_API_URL"]}/members/profile`;
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwtToken,
      },
      body: JSON.stringify(submitData),
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      setMessage(t("Failed to submit form ") + response.statusText);
      console.log("response: ", response);
    } else {
      setOpen(true);
    }
  };

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      setMessage(undefined);
    }, 250);
  }

  function changePhone(value) {
    if (value) {
      setData({ ...data, phoneNumber: value });
    }
  }

  return (
    <>
      {!!data && (
        <>
          <Grid item xs={12} md={12}>
            <Box>
              <Typography variant="h4" sx={{ mt: 2, mb: 1 }} gutterBottom>
                {translation.profile_header}
              </Typography>
            </Box>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <form
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
              onChange={handleInputChange}
              noValidate
            >
              <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <FormField
                      gridSize={6}
                      control={control}
                      label={translation.profile_first}
                      name="firstName"
                      defaultValue={data ? data.firstName : ""}
                      value={data ? data.firstName : ""}
                      onChange={(e) =>
                        setData({ ...data, firstName: e.target.value })
                      }
                      rules={{ required: t("First Name is required") }}
                    />

                    <FormField
                      gridSize={6}
                      control={control}
                      label={translation.profile_last}
                      name="lastName"
                      defaultValue={data ? data.lastName : ""}
                      value={data ? data.lastName : ""}
                      onChange={(e) =>
                        setData({ ...data, lastName: e.target.value })
                      }
                      rules={{ required: t("Last Name is required") }}
                    />
                  </Grid>
                  <FormField
                    gridSize={12}
                    control={control}
                    disabled
                    label={translation.profile_username}
                    name="username"
                    defaultValue={data ? data.userName : ""}
                    value={data ? data.userName : ""}
                    onChange={(e) =>
                      setData({ ...data, userName: e.target.value })
                    }
                  />

                  <PhoneField
                    marginBottom={"16px"}
                    gridSize={12}
                    control={control}
                    label={translation.profile_phone}
                    name="phoneNumber"
                    defaultValue={data ? data.phoneNumber : ""}
                    value={data ? data.phoneNumber : ""}
                    onChange={(e) => changePhone(e)}
                  />

                  <FormField
                    gridSize={12}
                    control={control}
                    disabled
                    label={translation.profile_email}
                    name="email"
                    defaultValue={data ? data.email : ""}
                    value={data ? data.email : ""}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" sx={{ mb: 1 }} gutterBottom>
                    {translation.avatar_header}
                  </Typography>
                  <UploadImage translation={translation} />
                </Grid>
              </Grid>

              <Button
                className={classes.saveButton}
                type="submit"
                variant="contained"
                size="small"
                sx={{ mt: 4, borderRadius: 1, minWidth: "150px" }}
                disabled={!isChanged}
              >
                {translation.button_save}
              </Button>

              <Controller
                name="status"
                control={control}
                defaultValue={data?.status || ""}
                render={({ field }) => (
                  <input type="text" {...field} hidden readOnly />
                )}
              />
            </form>
          </Box>

          <Snackbar
            open={open || !!message}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={!message ? "success" : "error"}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {!!message ? message : translation["success_update_profile"]}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
};

export default withAuthenticator(ProfileForm);
