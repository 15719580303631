import {TabContext, TabList, TabPanel} from "@mui/lab";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import BusinessForm from "../business-form";
import BusinessSettings from "../business-settings/BusinessSettings";
import AdminstratorsList from "../../pages/administrator-list/AdministratorList";
import React, {useContext} from "react";
import {useAdminPortalTranslation} from "../../api/translationApi";
import UserContext from "../../contexts/UserContexts";
import LanguageContext from "../../contexts/LanguageContext";

const BusinessTabs = ({ businessData }) => {
    const { language } = useContext(LanguageContext);
    const {adminPortalTranslation} = useAdminPortalTranslation();
    const memberMangementString = language === "en_US" ? "Member Management" : "Gestion des membres";
    const { userGroupPrefix } = useContext(UserContext);
    const isAdmin = userGroupPrefix === "Admin";
    const isBusAdmin = userGroupPrefix === "BusinessAdmin";
    const [tabValue, setTabValue] = React.useState('1');
    const isRetail = businessData?.data?.isRetail;

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Business Tabs">
                        <Tab label={adminPortalTranslation["business_business_details"]} value="1" />
                        {(isAdmin || isBusAdmin) && (<Tab label={memberMangementString} value="2" />)}
                        {isAdmin && (<Tab label={adminPortalTranslation["administrator_section_header"]} value="3" />)}
                        {/*<Tab label={"Customers"} value="4" />*/}
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <BusinessForm
                        method="PUT"
                        isRetail={isRetail}
                        translation={adminPortalTranslation}
                        businessData={businessData?.data}
                    />
                </TabPanel>
                <TabPanel value="2">
                    <BusinessSettings businessData={businessData} />
                </TabPanel>
                {isAdmin && (<TabPanel value="3">
                    <AdminstratorsList translation={adminPortalTranslation} />
                </TabPanel>)}
                {/*<TabPanel value="4">*/}
                {/*    <CustomerList editRoute={"customers"} />*/}
                {/*</TabPanel>*/}
            </TabContext>
        </Box>
    )
}

export default BusinessTabs;