import {Controller} from "react-hook-form";
import {Grid, InputLabel} from "@mui/material";
import PhoneInput from "react-phone-number-input/input";
import {isValidPhoneNumber} from "react-phone-number-input";
import styles from "./PhoneField.module.css";
import useInternalTranslation from "../../hooks/useInternalTranslation";

const PhoneField = ({
  gridSize,
  label,
  name,
  defaultValue,
  control,
  onChange,
  value,
  marginBottom = "0px"
}) => {
  const { t } = useInternalTranslation();

  return (
    <Grid item xs={gridSize} marginBottom={marginBottom}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required: t("Phone number is required"),
          validate: (value) => {
            return (
              isValidPhoneNumber(value) ||
              t("Please enter a valid phone number")
            );
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <>
            <PhoneInput
              id={name}
              className={styles.phoneInput}
              required
              {...field}
              style={{
                border: error?.message
                  ? "1px solid #d32f2f"
                  : "1px solid rgba(0, 0, 0, 0.25)",
              }}
              onChange={(e) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
              value={value !== undefined ? value : field.value}
            />
            {!!error?.message && (
              <p
                style={{
                  fontSize: "13px",
                  color: "#d32f2f",
                }}
              >
                {error?.message}
              </p>
            )}
          </>
        )}
      />
    </Grid>
  );
};

export default PhoneField;
