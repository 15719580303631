import {useContext} from "react";
import {styled} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {Box, Button, CircularProgress, Tooltip,} from "@mui/material";
import {Link} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import "./DisplayList.css";
import LanguageContext from "../../contexts/LanguageContext";
import {useAdminPortalTranslation} from "../../api/translationApi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DisplayList = ({
  headers,
  listData,
  columns,
  type,
  isLoading,
  hasMore,
  handleLoadMoreClick,
  editRoute,
}) => {
  // const { businessId } = useParams();
  // const jwtToken = localStorage.getItem("idToken");

  const {
    adminPortalTranslation
  } = useAdminPortalTranslation();

  const { language } = useContext(LanguageContext);
  const loadMoreString = language === "en_US" ? "Load more" : "Charger plus";

  return (
    <>
      {isLoading ? (
        <>
          <Table size="large">
            <TableHead>
              <TableRow>
                {headers.map((header, indx) => (
                  <StyledTableCell key={"section1-" + header + indx}>
                    {header}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
          <Box
            display="flex"
            alignContent="center"
            justifyContent="center"
            sx={{ my: 10 }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Table size="large">
            <TableHead>
              <TableRow>
                {headers.map((header, idx) => (
                  <StyledTableCell key={"section2-" + header + idx}>{header}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listData.length === 0 ? (
                <TableRow>
                  <StyledTableCell colSpan={headers.length}>
                    {`No ${type} found. Please add a ${type}.`}
                  </StyledTableCell>
                </TableRow>
              ) : (
                listData.map((item, idx) => (
                  <StyledTableRow key={item.id + idx}>
                    {columns.map((column, idx2) => {
                      // ****
                      // this is a VERY temporary fix, this component and its API should be refactored
                      // generic components must be as generic as possible
                      // ****
                      if (idx2 === 2) {
                        return (
                          <StyledTableCell key={column + idx2}>
                            {column.dataField(item) === "Active"
                              ? adminPortalTranslation["subscription_active"]
                              : adminPortalTranslation["subscription_inactive"]}
                          </StyledTableCell>
                        );
                      }

                      return (
                        <StyledTableCell key={column + idx2}>
                          {column.dataField(item)}
                        </StyledTableCell>
                      );
                    })}
                    <StyledTableCell>
                      <Tooltip title="Edit">
                        <Link
                          className="icon"
                          to={
                            !!editRoute
                              ? `${editRoute}/${item.id}`
                              : `${item.id}`
                          }
                          state={{ selectedItem: item }}
                          style={{ padding: "4px" }}
                        >
                          <EditIcon />
                        </Link>
                      </Tooltip>
                      {type === "business" && (
                        <Tooltip title="View Customers">
                          <Link
                            className="icon"
                            state={{ selectedItem: item }}
                            to={`${item.id}/customers`}
                            style={{ padding: "4px" }}
                          >
                            <PeopleIcon />
                          </Link>
                        </Tooltip>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
          {hasMore() ? (
            <Button
              component={Link}
              className="loadMoreLink"
              onClick={handleLoadMoreClick}
              sx={{ mt: 3 }}
            >
              {loadMoreString}
            </Button>
          ) : null}
        </>
      )}
    </>
  );
};

export default DisplayList;
