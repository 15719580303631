import {useContext} from "react";
import LanguageContext from "../contexts/LanguageContext";

const useInternalTranslation = () => {
  const { language } = useContext(LanguageContext);

  function t(english) {
    let result = english;
    if (language === "fr_CA") {
      switch (english?.toLowerCase().trim()) {
        case "There are no active products available".toLowerCase():
          result = "Aucun produit actif n’est disponible";
          break;
        case "There are no active products for this customer".toLowerCase():
          result = "Il n'y a aucun produit actif pour ce client";
          break;
        case "Invitation successfully sent!".toLowerCase():
          result = "Invitation envoyée avec succès!";
          break;
        case "There was a problem sending an invite, please try again".toLowerCase():
          result = "Un problème est survenu lors de l’envoi de l’invitation, veuillez réessayer";
          break;
        case "Failed to fetch License data".toLowerCase():
          result = "Échec de la récupération des données de licence";
          break;
        case "expired":
          result = "expiré";
          break;
        case "Failed to Create Member. Member already exists with this email address".toLowerCase():
          result = "Échec de la création du membre. Un membre existe déjà avec cette adresse courriel";
          break;
        case "please enter a valid phone number":
          result = "Veuillez entrer un numéro de téléphone valide au format +[Code du pays][Numéro] (ex. : +1234567890)";
          break;
        case "Business Name".toLowerCase():
          result = "Nom de l’entreprise";
          break;
        case "Business Name is required".toLowerCase():
          result = "Le nom de l’entreprise est requis";
          break;
        case "Business Name should be at least 2 characters".toLowerCase():
          result = "Le nom de l’entreprise doit contenir au moins 2 caractères";
          break;
        case "email has improper format":
          result = "Le format de l'email est incorrect";
          break;
        case "email is required":
          result = "L'email est requis";
          break;
        case "No products found".toLowerCase():
          result = "Aucun produit trouvé";
          break;
        case "First Name".toLowerCase():
          result = "Prénom";
          break;
        case "First Name should be at least 1 characters".toLowerCase():
          result = "Le prénom doit contenir au moins 1 caractère";
          break;
        case "first name is required":
          result = "Le prénom est requis";
          break;
        case "Last Name".toLowerCase():
          result = "Nom de famille";
          break;
        case "last name is required":
          result = "Le nom de famille est requis";
          break;
        case "Last Name should be at least 1 characters".toLowerCase():
          result = "Le nom de famille doit contenir au moins 1 caractère";
          break
        case "Phone".toLowerCase():
          result = "Téléphone";
          break;
        case "phone number is required":
          result = "L'phone est requis";
          break;
        case "failed to submit form":
          result = "Échec de la soumission du formulaire";
          break;
        case "back to list":
          result = "Retour à la liste";
          break;
        case "business name is required":
          result = "Le nom de l'entreprise est requis";
          break;
        case "sub domain has improper format":
          result = "Le format du sous-domaine est incorrect";
          break;
        case "sub domain is required":
          result = "Le sous-domaine est requis";
          break;
        case "add product":
          result = "Ajouter un produit";
          break;
        case "edit product":
          result = "Modifier le produit";
          break;
        case "product name is required":
          result = "Le nom du produit est requis";
          break;
        case "product duration is required":
          result = "La durée du produit est requise";
          break;
        case "the duration must be between 1-99":
          result = "La durée doit être comprise entre 1 et 99";
          break;
        case "product duration type is required":
          result = "Le type de durée du produit est requis";
          break;
        case "product description is required":
          result = "La description du produit est requise";
          break;
        case "product price is required":
          result = "Le prix du produit est requis";
          break;
        case "upload of avatar in progress":
          result = "Téléversement de l'avatar en cours";
          break;
        case "upload of logo in progress":
          result = "Téléversement du logo en cours";
          break;
        case "please upload a png or jpeg with a maximum size of 800x800 pixels":
          result =
            "Veuillez téléverser un fichier PNG ou JPEG d'une taille maximale de 800x800 pixels";
          break;
        case "no customer found":
          result = "Aucun client trouvé";
          break;
        case "no customer was found with the provided email address":
          result = "Aucun client n'a été trouvé avec l'adresse email fournie";
          break;
        case "Email".toLowerCase():
          result = "Courriel";
          break;
        case "Loading...".toLowerCase():
          result = "Chargement…";
          break;
        case "Error fetching products...".toLowerCase():
          result = "Erreur lors de la récupération des produits…";
          break;
        case "this email is already registered with another user.  choose a different email":
          result =
            "Ce courriel est déjà enregistré avec un autre utilisateur. Veuillez choisir un autre courriel";
          break;
        case "day(s)":
          result = "Jour(s)";
          break;
        case "months(s)":
          result = "Mois";
          break;
        case "years(s)":
          result = "An(s)";
          break;
        case "sign in with google":
          result = "Se connecter avec Google.";
          break;
        case "successfully updated language preference":
          result = "Préférence linguistique mise à jour avec succès.";
          break;
        case "failed to update language preference":
          result = "Échec de la mise à jour de la préférence linguistique";
          break;
        default:
          result = english;
          break;
      }
    }
    if (language === "en_US") {
      switch (english?.toLowerCase().trim()) {
        case "please enter a valid phone number":
          result = "Please enter a valid phone number in the format +[Country Code][Number] (e.g., +1234567890)";
          break;
        default:
          result = english;
      }
    }
    return result;
  }

  return {
    t,
  };
};

export default useInternalTranslation;
