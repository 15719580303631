import {TextField} from "@mui/material";
import {Controller} from "react-hook-form";
import React from "react";

const FormFieldV2 = ({ control, label, disabled = false, name, rules, error, helperText }) => {
    const formInputStyles = {
        bgcolor: "#F1F3F4",
        borderRadius: 1,
        "& .MuiFormHelperText-root": {
            color: "red",
            margin: 0,
            padding: 1,
            backgroundColor: "#fff",
        },
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules ? rules : null}
            render={({ field }) => (
                <div style={{ padding: "6px", width: "100%" }}>
                <div>{label}</div>
                <TextField
                    {...field}
                    disabled={disabled}
                    fullWidth
                    sx={formInputStyles}
                    error={!!error}
                    helperText={helperText}
                />
                </div>
            )}
        />
    )
}

export default FormFieldV2;