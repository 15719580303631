import React, {useContext, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {Box, Button, Grid, InputLabel, MenuItem, Select, TextField, Typography,} from "@mui/material";
import Title from "../title/Title";
import classes from "./CustomerForm.module.css";
import UserContext from "../../contexts/UserContexts";
import PhoneField from "../phone-field/PhoneField";
import {useAdminPortalTranslation} from "../../api/translationApi";
import LanguageContext from "../../contexts/LanguageContext";
import useInternalTranslation from "../../hooks/useInternalTranslation";
import {useBusinessLicenseData} from "../../hooks/useBusinessLicenseData";
import {DisplayProduct, formInputStyles} from "./lib";
import useSnackBar from "../snack-bar/useSnackBar";

const AddCustomerForm = () => {
        const navigate = useNavigate();
        const {handleSubmit, control, register} = useForm();
        const {businessId} = useParams();
        const {jwtToken} = useContext(UserContext);
        const location = useLocation();
        const {selectedItem} = location.state || {};
        const {t} = useInternalTranslation();
        const {adminPortalTranslation} = useAdminPortalTranslation();
        let {totalLicenses, businessProduct} = useBusinessLicenseData(jwtToken, businessId);
        const {language} = useContext(LanguageContext);
        const { show, CustomSnackBar} = useSnackBar();

        const [planStatus] = useState(selectedItem?.status || "Active");

        let url = `${process.env["REACT_APP_PORTAL_API_URL"]}/businesses/${businessId}/members`;

        const onSubmit = async (data) => {
            const memberData = {
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: String(data.phoneNumber),
                email: data.email,
                status: planStatus,
                memberLanguage: data.memberLanguage,
            };

            const options = {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + jwtToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(memberData),
            };
            const response = await fetch(url, options);

            if (!response.ok) {
                if (response.status === 409) {
                    show(t("Failed to Create Member. Member already exists with this email address"), "error");
                } else {
                    const errorData = await response.json();
                    console.error(`Error: ${response.status} ${response.statusText}`);
                    show(errorData.message || "Failed to submit form", "error");
                }
            } else {
                show("Customer Created");
                setTimeout(() => {
                    navigate(`/businesses/${businessId}/customers`);
                }, 700);
            }
        };

    const getProducts = () => {
        if (businessProduct) {
            return DisplayProduct(language,businessProduct,false);
        } else {
            return <>{t("There are no products available for this business")}</>
        }
    }

        return (
            <>
                <CustomSnackBar/>
                <FormProvider>
                    <form
                        method={"POST"}
                        className={classes.form}
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                    >
                        <Grid container columnSpacing={8}>
                            <Grid item xs={12} md={6}>
                                <Title>{adminPortalTranslation.add_customer}</Title>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor={"firstName"}>
                                            {adminPortalTranslation.business_first_name}
                                        </InputLabel>
                                        <Controller
                                            name={"firstName"}
                                            control={control}
                                            defaultValue={selectedItem?.firstName}
                                            value={selectedItem?.firstName}
                                            rules={{
                                                required: t("First Name is required"),
                                            }}
                                            render={({field, fieldState: {error}}) => (
                                                <TextField
                                                    id={"firstName"}
                                                    {...register("firstName")}
                                                    fullWidth
                                                    required
                                                    sx={formInputStyles}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputLabel htmlFor={"lastName"}>
                                            {adminPortalTranslation.business_last_name}
                                        </InputLabel>
                                        <Controller
                                            name={"lastName"}
                                            control={control}
                                            defaultValue={selectedItem?.lastName}
                                            value={selectedItem?.lastName}
                                            rules={{
                                                required: t("Last Name is required"),
                                            }}
                                            render={({field, fieldState: {error}}) => {
                                                return (
                                                    <TextField
                                                        id={"lastName"}
                                                        {...register("lastName")}
                                                        fullWidth
                                                        required
                                                        sx={formInputStyles}
                                                        error={!!error}
                                                        helperText={error ? error.message : null}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PhoneField
                                            gridSize={12}
                                            control={control}
                                            label={adminPortalTranslation.business_phone_number}
                                            name="phoneNumber"
                                            defaultValue={selectedItem?.phoneNumber}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel htmlFor={"email"}>
                                            {adminPortalTranslation.business_email_address}
                                        </InputLabel>
                                        <Controller
                                            name={"email"}
                                            control={control}
                                            defaultValue={selectedItem?.email}
                                            value={selectedItem?.email}
                                            rules={{
                                                required: t("Email is required"),
                                                validate: (value) => {
                                                    const emailPattern =
                                                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                                    return (
                                                        emailPattern.test(value) ||
                                                        t("Email has improper format")
                                                    );
                                                },
                                            }}
                                            render={({field, fieldState: {error}}) => (
                                                <TextField
                                                    id={"email"}
                                                    type="email"
                                                    {...register("email")}
                                                    fullWidth
                                                    required
                                                    sx={formInputStyles}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor={"memberLanguage"}>
                                            {adminPortalTranslation.language}
                                        </InputLabel>
                                        <Controller
                                            name={"memberLanguage"}
                                            control={control}
                                            defaultValue={selectedItem?.memberLanguage || "en_US"}
                                            render={({field, fieldState: {error}}) => (
                                                <Select
                                                    type="memberLanguage"
                                                    id="memberLanguage"
                                                    {...register("memberLanguage")}
                                                    fullWidth
                                                    sx={formInputStyles}
                                                    defaultValue={selectedItem?.memberLanguage || "en_US"}
                                                >
                                                    <MenuItem value={"en_US"}>English (US)</MenuItem>
                                                    <MenuItem value={"fr_CA"}>Français</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <>
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <Title>
                                            {adminPortalTranslation.business_subscription_plan}
                                        </Title>

                                        {getProducts()}

                                        <Grid container sx={{mt: 4}}>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    {adminPortalTranslation.totalLicenses}:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {totalLicenses || 0}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </>
                        </Grid>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={6}>
                                <Button
                                    className={classes.button}
                                    component={Link}
                                    type="button"
                                    to={`/businesses/${businessId}/customers`}
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    sx={{my: 4, borderRadius: 0}}
                                >
                                    {adminPortalTranslation.button_cancel || "Cancel"}
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    sx={{my: 4, borderRadius: 0}}
                                    type="submit"
                                >
                                    {adminPortalTranslation.button_save || "Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </>
        );
    }
;

export default AddCustomerForm;
