import React, {useContext, useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {UserContext} from "../../contexts/UserContext";

import {useAdminPortalTranslation} from "../../api/translationApi";
import ProfileTabs from "./ProfileTabs";

const Profile = () => {
  const { loading } = useContext(UserContext);
  const jwtToken = localStorage.getItem("idToken");
  const [profileData, setProfileData] = useState(null);
  const [profileLoading, setProfileLoading] = useState(true);
  const [profileError, setProfileError] = useState(null);
  const {
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/members/profile`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + jwtToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setProfileData(data);
        setProfileLoading(false);
      } catch (error) {
        setProfileError(error);
        setProfileLoading(false);
      }
    };

    fetchProfileData();
  }, []);

  if (loading || profileLoading) {
    return <div>Loading...</div>;
  }

  if (profileError) {
    return <div>Error: {profileError.message}</div>;
  }

  if (adminPortalTranslationIsLoading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error with Portal Admin Translations...</div>;
  }

  return (
    <>
      <Grid container spacing={4} marginBottom="32px">
        <Grid item xs={12}>
          <ProfileTabs profileData={profileData}  />
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
