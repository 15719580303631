import dayjs from "dayjs";
import {Grid, Typography} from "@mui/material";
import React from "react";

export const formatDate = (dateString, langPref = "en_US") => {
    const d = dayjs(dateString);
    if (d.year() > 2100) {
        return langPref === "en_US" ? "Recurring" : "Récurrent";
    } else {
        return d.format("MMM D, YYYY");
    }
};

export const hasExpired = (dateString) => {
    return dayjs(dateString).isBefore(new Date());
}

export const formInputStyles = {
    bgcolor: "#F1F3F4",
    borderRadius: 1,
    "& .MuiFormHelperText-root": {
        color: "red",
        margin: 0,
        padding: 1,
        backgroundColor: "#fff",
    },
};

export function DisplayProduct(language, memberProduct, showDates = true) {
    const notAvailableString =
        language === "en_US" ? "Not Available" : "Non disponible";

    return <div key={memberProduct?.id}>
        <Typography variant={"h6"} fontWeight={700}>
            {memberProduct?.productName ?? notAvailableString}
        </Typography>

        { showDates ?
        <Grid container marginTop={"12px"}>
            <Grid item xs={6}>
                <strong>Start Date</strong>
            </Grid>
            <Grid item xs={6}>
                <strong>End Date</strong>
            </Grid>
            <Grid item xs={6}>
                {formatDate(memberProduct?.startDate, language)}
            </Grid>
            <Grid item xs={6}>
                {formatDate(memberProduct?.endDate, language)}
            </Grid>
        </Grid>
            : <></>}

        {/*{hasExpired(memberProduct.endDate) ?*/}
        {/*    <Chip color="error" size="small"*/}
        {/*          label={t("Expired")}></Chip> : <></>}*/}
    </div>
}
