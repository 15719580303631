import {TabContext, TabList, TabPanel} from "@mui/lab";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import {useContext, useState} from "react";
import {useAdminPortalTranslation} from "../../api/translationApi";
import CustomerDetails from "../../components/profile/partials/customer-details";
import ProductDetails from "../../components/profile/partials/product-details";
import Settings from "../../components/profile/partials/settings";
import {UserContext} from "../../contexts/UserContext";

const ProfileTabs = ({ profileData }) => {
    const {adminPortalTranslation} = useAdminPortalTranslation();
    const [tabValue, setTabValue] = useState('1');
    const { userGroups } = useContext(UserContext);

    function canView() {
        if (userGroups.length === 0) return true;
        if (userGroups.includes("Admin")) return true;

        return false;
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="Profile Tabs">
                        <Tab label={adminPortalTranslation.profile_header} value="1" />
                        {canView() && (<Tab label={adminPortalTranslation.subscription_header} value="2" />)}
                        {(<Tab label={adminPortalTranslation.settings} value="3" />)}
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <CustomerDetails translation={adminPortalTranslation} />
                </TabPanel>
                { canView() && <TabPanel value="2">
                    <ProductDetails
                        profileData={profileData}
                        translation={adminPortalTranslation}
                    />
                </TabPanel> }
                <TabPanel value="3">
                    <Settings translation={adminPortalTranslation} />
                </TabPanel>
            </TabContext>
        </Box>
    )
}

export default ProfileTabs;