const baseUrl = process.env.REACT_APP_PORTAL_API_URL;

const getBusiness = async (idToken, businessId) => {
    const options = {
        method: "GET",
        headers: {
            Authorization: "Bearer " + idToken,
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(
        `${baseUrl}/businesses/${businessId}`,
        options);

    if (response.ok) {
        const data = await response.json();
        return data?.data;
    } else {
        console.log("Failed to fetch business", { error: response.statusText});
        throw new Error(response.statusText);
    }
}

const createBusiness = async (idToken, business) => {
    try {
        const createdBusiness = {...business, ...{status: "Active"}}
        const options = {
            method: "POST",
                headers: {
                    Authorization: "Bearer " + idToken,
                    "Content-Type": "application/json",
                },
            body: JSON.stringify(createdBusiness),
        }
        const response = await fetch(`${baseUrl}/businesses`, options)
        if (response.ok) {
            return await response.json();
        }
        return false;
    } catch (error) {
        console.error(`Error: ${error}`);
        throw new Error(error);
    }
}

const updateBusiness = async (idToken, business, businessId) => {
    try {
        const updatedBusiness = {...business, ...{status: "Active"}}
        const options = {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + idToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedBusiness),
        }
        const response = await fetch(`${baseUrl}/businesses/${businessId}`, options)
        if (response.ok) {
            return await response.json();
        }
        return false;
    } catch (error) {
        console.error(`Error: ${error}`);
        throw new Error(error);
    }
}

export {
    getBusiness,
    createBusiness,
    updateBusiness
}