import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import InviteMember from "./InviteMember";
import ConfirmInvite from "./ConfirmInvite";
import useSnackBar from "../../components/snack-bar/useSnackBar.js";
import UserContext from "../../contexts/UserContexts";
import {
  cancelInvite,
  fetchLicenseDetails,
  inviteMember,
  NoProductsException,
} from "../../utils/api.js";
import { useAdminPortalTranslation } from "../../api/translationApi.js";
import { jwtDecode } from "jwt-decode";
import useInternalTranslation from "../../hooks/useInternalTranslation";

export const useInviteMember = () => {
  const [open, setOpen] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [formData, setFormData] = useState({});
  const [licenseDetails, setLicenseDetails] = useState({});
  const { jwtToken } = useContext(UserContext);
  const [userName, setUserName] = useState();
  const { show: showSnackbar, CustomSnackBar, onClosing } = useSnackBar();
  const { adminPortalTranslation } = useAdminPortalTranslation();
  const { t } = useInternalTranslation();

  async function getLicenseDetails() {
    if (userName) {
      try {
        const licenseData = await fetchLicenseDetails(jwtToken, userName);
        setLicenseDetails(licenseData.data);
      } catch (err) {
        if (err instanceof NoProductsException) {
          showSnackbar(
            t("There are no active products for this customer"),
            "error"
          );
        } else {
          showSnackbar(
            err.message || t("Failed to fetch License data"),
            "error"
          );
        }
      }
    }
  }

  function decodeToken(jwtToken) {
    try {
      const decoded = jwtDecode(jwtToken);
      if (decoded) {
        setUserName(decoded["cognito:username"]);
      }
    } catch (err) {
      console.log("Failed to decode token");
    }
  }

  useEffect(() => {
    if (jwtToken) {
      decodeToken(jwtToken);
    }
  }, [jwtToken]);

  useEffect(() => {
    if (userName) {
      getLicenseDetails();
    }
  }, [userName]);

  function show() {
    onClosing();
    setOpen(true);
  }

  function hide() {
    setOpen(false);
    setFormComplete(false);
    setFormData({});
  }

  async function handleSubmit() {
    hide();

    const response = await inviteMember(jwtToken, formData);
    if (response.statusCode === 200 || response.statusCode === 201) {
      showSnackbar(t("Invitation successfully sent!"), "success");
      await getLicenseDetails();
    } else
      showSnackbar(
        `${t("There was a problem sending an invite, please try again")}: ${
          response.statusCode
        }`,
        "error"
      );
  }

  function CancelMember({ member, setMember }) {
    function handleCancel() {
      setMember(undefined);
    }

    async function handleConfirm() {
      const response = await cancelInvite(jwtToken, member?.id);
      if (
        response.statusCode === 200 ||
        response.statusCode === 202 ||
        response.statusCode === 204
      ) {
        showSnackbar(
          `${adminPortalTranslation?.cancelledInviteOne} ${member.firstName} ${adminPortalTranslation?.cancelledInviteTwo}`,
          "success"
        );
        setMember(undefined);
        getLicenseDetails();
      } else {
        showSnackbar(
          `${adminPortalTranslation?.cancelFailed}: ${response.statusCode}`,
          "error"
        );
      }
    }

    return (
      <Dialog open={!!member}>
        <DialogTitle>
          {adminPortalTranslation?.cancelInviteComfirmation}
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {adminPortalTranslation?.cancelInviteMessage} {member?.firstName}?
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 15,
              marginTop: 25,
            }}
          >
            <Button
              variant="contained"
              disableElevation
              sx={{
                backgroundColor: "#F1F3F4",
                color: "black",
                "&:hover": {
                  backgroundColor: "#969696",
                  color: "#fff",
                },
                fontWeight: 600,
              }}
              onClick={() => handleCancel()}
            >
              {adminPortalTranslation?.cancel_button}
            </Button>
            <Button
              variant="contained"
              disableElevation
              color="secondary"
              sx={{
                fontWeight: 600,
              }}
              onClick={() => handleConfirm()}
            >
              {adminPortalTranslation?.confirm_button}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  function InviteMemberPrompt() {
    return (
      <>
        <CustomSnackBar />
        <Dialog open={open} onClose={hide}>
          {!formComplete ? (
            <InviteMember
              goBack={hide}
              formData={formData}
              setFormData={setFormData}
              setFormComplete={setFormComplete}
              remainingInvites={licenseDetails?.availableLicenses}
            />
          ) : (
            <ConfirmInvite
              goBack={() => setFormComplete(false)}
              formData={formData}
              submitInvite={handleSubmit}
            />
          )}
        </Dialog>
      </>
    );
  }

  return { show, hide, InviteMemberPrompt, licenseDetails, CancelMember };
};
