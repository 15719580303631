import {Typography} from "@mui/material";
import React from "react";

const PageHeader = ({ title, subtitle, styles }) => {
    return (
        <div style={styles}>
            <Typography variant="h4">{title}</Typography>
            { subtitle ? <Typography variant="p">{subtitle}</Typography> : <></> }
        </div>
    )
}

export default PageHeader;