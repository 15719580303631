import {React, useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import {Grid, Typography, useTheme} from "@mui/material";
import Faq from "../../components/contentful/faq";
import IndustryNewsContainer from "../../components/contentful/industry-news/IndustryNews";
import ResourceCenter from "../../components/contentful/resource-center/ResourceCenter";
import MobileApp from "../../components/mobile-app/MobileApp";

// import contexts
import {UserContext as PortalData} from "../../contexts/UserContext";

import {useAdminPortalTranslation} from "../../api/translationApi";
// import AcceptanceModal from "../../features/acceptance-modal/AcceptanceModal";
import {useFailedGoogleAuth} from "../../hooks/useFailedGoogleAuth";

import useSnackBar from "../../components/snack-bar/useSnackBar";
import useFirstTimeLogin from "../../features/acceptance-modal/useFirstTimeLogin";
import YouTubeEmbed from "../../components/youtube-embed.js/YouTubeEmbed";

const Dashboard = () => {
  const { data } = useContext(PortalData);
  const { FirstTimeLoginModal } = useFirstTimeLogin();
  const theme = useTheme();

  const [loading, setLoading] = useState(true);

  // this hook detects errors in search params and returns a function to clear it
  const { error, clearError } = useFailedGoogleAuth();

  const { show, CustomSnackBar } = useSnackBar();

  if (error) {
    show(error, "error");
    clearError();
  }

  const {
    adminPortalTranslation,
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  if (adminPortalTranslationIsLoading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error with Portal Admin Translations...</div>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CustomSnackBar />
      {loading ? (
        <></>
      ) : (
        <Box overflow="hidden">
          <Container sx={{ mt: 4, mb: 4 }}>
            <Box sx={{ pt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <>
                    <Typography variant="h4" mb={1}>
                      {adminPortalTranslation.welcome_message}
                    </Typography>
                    <Typography variant="body1" mb={3}>
                      {adminPortalTranslation.logged_in_message}
                    </Typography>
                  </>
                  <MobileApp />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    display="flex"
                    sx={{
                      justifyContent: "flex-end",
                      [theme.breakpoints.down(900)]: {
                        justifyContent: "left",
                      },
                    }}
                  >
                    <YouTubeEmbed
                      width="100%"
                      src="https://www.youtube.com/embed/9l3Wo4K_zBw?si=iZBUZFD3EmLYhoJ1"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <IndustryNewsContainer />
            <ResourceCenter />
            <Faq />
          </Container>
          <FirstTimeLoginModal />
        </Box>
      )}
      {/**/}
      {/*<AcceptanceModal />*/}
    </Box>
  );
};

export default Dashboard;
