
const getProducts = async ({ idToken, isAdmin }) => {
    const baseURL = process.env["REACT_APP_PORTAL_API_URL"];

    if (isAdmin) {
        const response = await fetch(`${baseURL}/products`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + idToken,
            },
        });

        if (!response.ok) {
            throw new Error("Failed to fetch products");
        }

        const productList = await response.json();
        const filteredData = productList.data.filter((item) => item !== null);

        return filteredData;
    } else {
        console.log("Not entitled to fetch products");
        return []
    }
};

export {
    getProducts,
}
