import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Fab,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useContext, useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import UserContext from "../contexts/UserContexts";
import { getBusinesses } from "../utils/api";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { useAdminPortalTranslation } from "../api/translationApi";

export default function Reports() {
  const { userGroupPrefix } = useContext(UserContext);
  const isAdmin = userGroupPrefix === "Admin";
  const [businesses, setBusinesses] = useState([]);
  const [businessId, setBusinessId] = useState();
  const [displayData, setDisplayData] = useState(undefined);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { adminPortalTranslation } = useAdminPortalTranslation();

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    async function getReportBusinesses() {
      const token = localStorage.getItem("idToken");

      if (isAdmin) {
        const allBusinessData = await getBusinesses(token);
        setBusinesses(allBusinessData?.data);
        setBusinessId(allBusinessData?.data[0].id);
      } else {
        const tokenData = parseJwt(token);
        setBusinessId(tokenData.businessId);
      }
    }
    getReportBusinesses();
  }, [userGroupPrefix]);

  const reports = [
    {
      title: adminPortalTranslation?.enrolledAndEligibleUsers,
      description: adminPortalTranslation?.userEngagementAndOnboarding,
      api: `${process.env["REACT_APP_API_URL"]}/reports/businesses/${businessId}/eligible-users?`,
    },
    {
      title: adminPortalTranslation?.scansByChannel,
      description: adminPortalTranslation?.scanDistributionAndTrends,
      api: `${process.env["REACT_APP_API_URL"]}/reports/businesses/${businessId}/scans-by-channel?`,
    },
    {
      title: adminPortalTranslation?.scamsIdentified,
      description: adminPortalTranslation?.scamDetectionAndEffectiveness,
      api: `${process.env["REACT_APP_API_URL"]}/reports/businesses/${businessId}/scams-identified?`,
    },
    {
      title: adminPortalTranslation?.scansByService,
      description: adminPortalTranslation?.scanActivityAndTrends,
      api: `${process.env["REACT_APP_API_URL"]}/reports/businesses/${businessId}/scans-by-business?`,
    },
    {
      title: adminPortalTranslation?.scansStatus,
      description: adminPortalTranslation?.scanCompletionStatus,
      api: `${process.env["REACT_APP_API_URL"]}/reports/businesses/${businessId}/scans-by-status?`,
    },
    {
      title: adminPortalTranslation?.scanAnalytics,
      description: adminPortalTranslation?.scanAnalyticsMessage,
      api: `${process.env["REACT_APP_API_URL"]}/reports/businesses/${businessId}/disputes-by-business?`,
    },
  ];

  const Report = ({ report }) => {
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);

    function getDateAsString(date) {
      let dateToConvert = date;

      if (date?.year && date?.month !== undefined && date?.day) {
        dateToConvert = new Date(date.year, date.month, date.day);
      }

      if (isNaN(dateToConvert)) {
        console.error("Invalid date provided");
        return null;
      }

      const dayjs = require("dayjs");
      var customParseFormat = require("dayjs/plugin/customParseFormat");
      dayjs.extend(customParseFormat);

      const newDate = dayjs(dateToConvert);

      if (!newDate.isValid()) {
        console.error("Failed to parse the date");
        return null;
      }

      const adjustedDate = newDate.subtract(1, "month");

      return adjustedDate.format("MM/DD/YYYY");
    }

    async function onDownload() {
      try {
        const jwtToken = localStorage.getItem("idToken");

        const url = `${report.api}startDate=${getDateAsString(
          dateRange[0]
        )}&endDate=${getDateAsString(dateRange[1])}`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch Report data");
        }

        const jsonResponse = await response.json();
        setDisplayData(jsonResponse.data);
        handleOpen();
      } catch (err) {
        console.error(err);
      }
    }

    return (
      <Grid sx={{ width: 350 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: 400,
            background: "#F1F7F9",
            border: "1px solid #F1F7F9",
            borderRadius: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              background: "#F1F7F9",
              alignItems: "center",
              padding: 2.5,
              borderRadius: "15px 15px 0px 0px",
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>{report?.title}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flexGrow: 1,
              padding: 2.5,
              background: "white",
              border: "1px solid #DAE1E3",
              borderRadius: "0px 0px 15px 15px",
              width: "100%",
            }}
          >
            <Typography>{report?.description}</Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                marginTop: "auto",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <DatePicker
                value={dateRange}
                onChange={setDateRange}
                range
                dateSeparator=" - "
                style={{
                  height: 40,
                  fontSize: 14,
                  textAlign: "center",
                  width: 250,
                }}
              />
              <Fab
                size="small"
                color="secondary"
                aria-label="download"
                sx={{
                  boxShadow: "none",
                }}
                onClick={() => onDownload()}
              >
                <DownloadIcon />
              </Fab>
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  };

  const DialogWithTable = ({ open, onClose, data }) => {
    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
    };

    const convertSecondsToHours = (seconds) => {
      return (seconds / 3600).toFixed(0);
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {Object.entries(data).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {convertCamelCaseToReadable(key)}
                    </TableCell>
                    <TableCell align="right">
                      {key === "reportURL" ? (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            padding: 0,
                            margin: 0,
                            justifyContent: "right",
                          }}
                        >
                          <div>
                            <IconButton
                              aria-label="open"
                              size="large"
                              onClick={() => window.open(value, "_blank")}
                            >
                              <FileOpenIcon fontSize="inherit" />
                            </IconButton>
                            <Typography
                              sx={{ fontSize: 10, textAlign: "center" }}
                            >
                              {adminPortalTranslation?.download}
                            </Typography>
                          </div>
                          <div>
                            <IconButton
                              aria-label="copy"
                              size="large"
                              onClick={() => copyToClipboard(value)}
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                            <Typography
                              sx={{ fontSize: 10, textAlign: "center" }}
                            >
                              {adminPortalTranslation?.copy}
                            </Typography>
                          </div>
                        </div>
                      ) : key === "expires" ? (
                        <span>
                          {convertSecondsToHours(value)}{" "}
                          {adminPortalTranslation?.hours}
                        </span>
                      ) : (
                        String(value)
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose} color="secondary">
            {adminPortalTranslation?.close}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {!!displayData && (
        <DialogWithTable open={open} onClose={handleClose} data={displayData} />
      )}
      <Typography sx={{ fontSize: 32, fontWeight: 600 }}>
        {adminPortalTranslation?.reports}
      </Typography>
      {isAdmin && (
        <Select
          sx={{ width: "50%", height: 40, marginTop: 2 }}
          value={isAdmin && businesses.length === 0 ? "" : businessId}
          onChange={(e) => {
            setBusinessId(e.target.value);
          }}
        >
          {businesses.map((business, index) => (
            <MenuItem key={index} value={business?.id}>
              {business?.businessName || "Couldn't find business name"}
            </MenuItem>
          ))}
        </Select>
      )}

      <Divider sx={{ marginTop: 5 }} />

      <Grid
        container
        sx={{
          marginTop: 5,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: 5,
        }}
      >
        {reports.map((report, index) => (
          <Report key={index} report={report} />
        ))}
      </Grid>
    </>
  );
}

const convertCamelCaseToReadable = (str) => {
  const readableString = str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space between camel case words
    .replace(/^./, (match) => match.toUpperCase()); // Capitalize the first letter

  if (localStorage.getItem("language") === "en_US") return readableString;

  switch (readableString) {
    case "Total Signed Up Count":
      return "Nombre total d'inscriptions";
    case "Total Eligible Count":
      return "Nombre total admissible";
    case "Report URL":
      return "URL du rapport";
    case "Download":
      return "Télécharger";
    case "Expires":
      return "Expire dans 48 heures";
    case "Email Count":
      return "Nombre de courriels";
    case "Email Percentage":
      return "Pourcentage de courriels";
    case "Image Count":
      return "Nombre d'images";
    case "Image Percentage":
      return "Pourcentage d'images";
    case "Url Count":
      return "Nombre d'URLs";
    case "Url Percentage":
      return "Pourcentage d'URLs";
    case "Text Count":
      return "Nombre de textes";
    case "Text Percentage":
      return "Pourcentage de textes";
    case "Copy":
      return "Copier";
    case "Close":
      return "Fermer";
    case "Scam Count":
      return "Nombre d'arnaques";
    case "Failed Count":
      return "Nombre d'échecs";
    case "Completed Count":
      return "Nombre terminé";
    case "In Process Count":
      return "Nombre en cours";
    case "Pending Count":
      return "Nombre en attente";
    case "Scan Count":
      return "Nombre de balayages";
    case "Total Dispute Count":
      return "Nombre Total de Litiges";
    default:
      return readableString;
  }
};
