import {useEffect, useState} from "react";
import {fetchBusinessLicenseData} from "../api/productLicense";
import {fetchAuthSession} from "aws-amplify/auth";

export const useBusinessLicenseData = (jwtToken, businessId) => {
  const [availableLicenses, setAvailableLicenses] = useState(0);
  const [totalLicenses, setTotalLicenses] = useState(0);
  const [businessProduct, setBusinessProduct] = useState({});
  const [idToken, setIdToken] = useState();

  useEffect(() => {
    const fetchIdToken = async () => {
      try {
        const session = await fetchAuthSession();
        const token = session.tokens.idToken;
        setIdToken(token);
      } catch (error) {
        console.error('Error fetching ID Token:', error);
      }
    };

    fetchIdToken()
  }, []);

  useEffect(() => {
    const fetchLicenseData = async () => {
      if (!idToken) return;

      try {
        const data = await fetchBusinessLicenseData(idToken,businessId);
        if (data) {
          setTotalLicenses(data.numberOfLicenses);
          setAvailableLicenses(data.availableLicenses);
          setBusinessProduct(data.product);
        } else {
          throw new Error("No License data available");
        }
      } catch (err) {
        throw new Error(err.message || "Failed to fetch License data");
      }
    }

    if (idToken) {
      fetchLicenseData(idToken);
    }
  }, [idToken]);

  return { availableLicenses, totalLicenses, businessProduct };
};
