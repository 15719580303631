import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import Title from "../title/Title";
import classes from "./CustomerForm.module.css";
import PhoneField from "../phone-field/PhoneField";
import {useAdminPortalTranslation} from "../../api/translationApi";
import LanguageContext from "../../contexts/LanguageContext";
import useInternalTranslation from "../../hooks/useInternalTranslation";
import {DisplayProduct, formInputStyles} from "./lib";
import {fetchLicenseDetails, NoProductsException} from "../../utils/api";
import {fetchAuthSession} from "aws-amplify/auth";
import useSnackBar from "../snack-bar/useSnackBar";

const EditCustomerForm = () => {
        // const navigate = useNavigate();
        const [availableLicenses, setAvailableLicenses] = useState(0);
        const [totalLicenses, setTotalLicenses] = useState(0);
        const {handleSubmit, control, register} = useForm();
        const {businessId, customerId} = useParams();
        const [idToken, setIdToken] = useState();
        const location = useLocation();
        const {selectedItem} = location.state || {};
        const {t} = useInternalTranslation();
        const {adminPortalTranslation} = useAdminPortalTranslation();
        const {language} = useContext(LanguageContext);
        const {show, CustomSnackBar} = useSnackBar();

        useEffect(() => {
            const fetchIdToken = async () => {
                try {
                    const session = await fetchAuthSession();
                    const token = session.tokens.idToken;
                    setIdToken(token);
                } catch (error) {
                    console.error('Error fetching ID Token:', error);
                }
            };

            fetchIdToken()
        }, []);

        useEffect(() => {
            const fetchLicenseData = async () => {
                if (!idToken) return;

                try {
                    let data = await fetchLicenseDetails(idToken,selectedItem.userName);
                    if (data?.data) {
                        setTotalLicenses(data.data.numberOfLicenses);
                        setAvailableLicenses(data.data.availableLicenses);
                    } else {
                        show(t("No License data available"), "error");
                    }
                } catch (err) {
                    if (err instanceof NoProductsException) {
                        show(t("There are no active products for this customer"), "error");
                    } else {
                        show(err.message || t("Failed to fetch License data"), "error");
                    }
                }
            }

            if (idToken) {
                fetchLicenseData(idToken);
            }
        }, [idToken]);

        const [planStatus] = useState(selectedItem?.status || "Active");

        let url = `${process.env["REACT_APP_PORTAL_API_URL"]}/businesses/${businessId}/members/${customerId}`;

        const onSubmit = async (data) => {
            const memberData = {
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: String(data.phoneNumber),
                email: data.email,
                status: planStatus,
                memberLanguage: data.memberLanguage,
            };

            const options = {
                method: "PUT",
                headers: {
                    Authorization: "Bearer " + idToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(memberData),
            };
            const response = await fetch(url, options);

            if (!response.ok) {
                const errorData = await response.json();
                console.error(`Error: ${response.status} ${response.statusText}`);
                show(errorData.message || "Failed to submit form", "error");
            } else {
                debugger;
                show("Customer Updated");
                setTimeout(() => {
                    // navigate("/")
                }, 1000);
            }
        };

        const getProducts = () => {
            if (selectedItem.productDetails.length > 1) {
                // More than one product is an edge case
            }
            let memberProduct = selectedItem?.productDetails?.at(0);

            return DisplayProduct(language, memberProduct);
        }

        function getInvitedMember() {
            if (selectedItem?.groupOwnerId && selectedItem?.groupOwnerId !== "undefined") {
                return (
                    <>
                        <Tooltip leaveDelay={400} title={"Business ID: " + businessId + " Product Owner ID: " + selectedItem?.groupOwnerId}>
                        <Chip color="success" label={"Invited Member"}/>
                        </Tooltip>
                    </>
                )
            } else {
                return (<></>)
            }
        }

        return (
            <>
                <CustomSnackBar/>
                <FormProvider>
                    <form
                        method={"POST"}
                        className={classes.form}
                        onSubmit={handleSubmit(onSubmit)}
                        noValidate
                    >
                        <Grid container columnSpacing={8}>
                            <Grid item xs={12} md={6}>
                                <Title>{adminPortalTranslation.edit_customer}</Title>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <InputLabel htmlFor={"firstName"}>
                                            {adminPortalTranslation.business_first_name}
                                        </InputLabel>
                                        <Controller
                                            name={"firstName"}
                                            control={control}
                                            defaultValue={selectedItem?.firstName}
                                            value={selectedItem?.firstName}
                                            rules={{
                                                required: t("First Name is required"),
                                            }}
                                            render={({field, fieldState: {error}}) => (
                                                <TextField
                                                    id={"firstName"}
                                                    {...register("firstName")}
                                                    fullWidth
                                                    required
                                                    sx={formInputStyles}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <InputLabel htmlFor={"lastName"}>
                                            {adminPortalTranslation.business_last_name}
                                        </InputLabel>
                                        <Controller
                                            name={"lastName"}
                                            control={control}
                                            defaultValue={selectedItem?.lastName}
                                            value={selectedItem?.lastName}
                                            rules={{
                                                required: t("Last Name is required"),
                                            }}
                                            render={({field, fieldState: {error}}) => {
                                                return (
                                                    <TextField
                                                        id={"lastName"}
                                                        {...register("lastName")}
                                                        fullWidth
                                                        required
                                                        sx={formInputStyles}
                                                        error={!!error}
                                                        helperText={error ? error.message : null}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor={"username"}>
                                            {adminPortalTranslation.profile_username}
                                        </InputLabel>
                                        <Controller
                                            name={"username"}
                                            control={control}
                                            defaultValue={selectedItem?.userName}
                                            value={selectedItem?.userName}
                                            render={({field, fieldState: {error}}) => {
                                                return (
                                                    <TextField
                                                        id={"username"}
                                                        {...register("username")}
                                                        fullWidth
                                                        required
                                                        sx={formInputStyles}
                                                        error={!!error}
                                                        helperText={error ? error.message : null}
                                                        disabled
                                                        InputProps={{
                                                            inputProps: {
                                                                readOnly: true,
                                                            },
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PhoneField
                                            gridSize={12}
                                            control={control}
                                            label={adminPortalTranslation.business_phone_number}
                                            name="phoneNumber"
                                            defaultValue={selectedItem?.phoneNumber}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputLabel htmlFor={"email"}>
                                            {adminPortalTranslation.business_email_address}
                                        </InputLabel>
                                        <Controller
                                            name={"email"}
                                            control={control}
                                            defaultValue={selectedItem?.email}
                                            value={selectedItem?.email}
                                            rules={{
                                                required: t("Email is required"),
                                                validate: (value) => {
                                                    const emailPattern =
                                                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                                    return (
                                                        emailPattern.test(value) ||
                                                        t("Email has improper format")
                                                    );
                                                },
                                            }}
                                            render={({field, fieldState: {error}}) => (
                                                <TextField
                                                    disabled={true}
                                                    id={"email"}
                                                    type="email"
                                                    {...register("email")}
                                                    fullWidth
                                                    required
                                                    sx={formInputStyles}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor={"memberLanguage"}>
                                            {adminPortalTranslation.language}
                                        </InputLabel>
                                        <Controller
                                            name={"memberLanguage"}
                                            control={control}
                                            defaultValue={selectedItem?.memberLanguage || "en_US"}
                                            render={({field, fieldState: {error}}) => (
                                                <Select
                                                    type="memberLanguage"
                                                    id="memberLanguage"
                                                    {...register("memberLanguage")}
                                                    fullWidth
                                                    sx={formInputStyles}
                                                    defaultValue={selectedItem?.memberLanguage || "en_US"}
                                                >
                                                    <MenuItem value={"en_US"}>English (US)</MenuItem>
                                                    <MenuItem value={"fr_CA"}>Français</MenuItem>
                                                </Select>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <>
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <Title>
                                            {adminPortalTranslation.business_subscription_plan}
                                        </Title>

                                        {getProducts()}

                                        <div>{getInvitedMember()}</div>

                                        <Grid container sx={{mt: 4}}>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    {adminPortalTranslation.totalLicenses}:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {totalLicenses || 0}
                                            </Grid>
                                            <>
                                                <Grid item xs={6} sx={{mt: 1}}>
                                                    <Typography fontWeight={700}>
                                                        {adminPortalTranslation.availableLicenses}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sx={{mt: 1}}>
                                                    {availableLicenses || 0}
                                                </Grid>
                                            </>
                                        </Grid>

                                    </Box>
                                </Grid>
                            </>
                        </Grid>
                        <Grid container spacing={4} alignItems="center">
                            <Grid item xs={6}>
                                <Button
                                    className={classes.button}
                                    component={Link}
                                    type="button"
                                    to={`/businesses/${businessId}/customers`}
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    sx={{my: 4, borderRadius: 0}}
                                >
                                    {adminPortalTranslation.button_cancel || "Cancel"}
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    sx={{my: 4, borderRadius: 0}}
                                    type="submit"
                                >
                                    {adminPortalTranslation.button_save || "Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </>
        );
    }
;

export default EditCustomerForm;
