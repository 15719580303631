import {React, useContext, useEffect, useState} from "react";
import {ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {AppBar, Avatar, Container, Menu, MenuItem, Tooltip,} from "@mui/material";
import CompanyLogo from "../../assets/KnowScam_white.svg";
import CompanyLogoSmall from "../../assets/KnowScam_white-small.png";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {PATH_CONSTANTS} from "../../constants/pathConstants";
import {withAuthenticator} from "@aws-amplify/ui-react";
import "./Navigation.modules.css";
import UserContext from "../../contexts/UserContexts";
import {UserContext as PortalData} from "../../contexts/UserContext";
import useAvatar from "../avatar/useAvatar";
import useCobrand from "../co-branding/useCobrand";
import {useAdminPortalTranslation} from "../../api/translationApi";

function showLogo(customLogo) {
  if (customLogo) {
    return <img src={customLogo} alt="Scamnetic" width="auto" height="65px" />;
  } else {
    return <img src={CompanyLogo} alt="Scamnetic" width="auto" height="35px" />;
  }
}

const Navigation = ({ signOut, user }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userInitials, setUserInitials] = useState("");
  const { userGroupPrefix } = useContext(UserContext);
  const { avatar, reloadAvatar } = useAvatar(user.username);
  const { data, loading } = useContext(PortalData);
  const { customLogo, theme, themeStyles, logoLoaded } = useCobrand();
  const navigate = useNavigate();
  const businessID = data?.businessId;

  const {
    adminPortalTranslation,
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  // const styles = customLogo ? ScamneticBrand : CustomBrand;
  useEffect(() => {
    reloadAvatar(user.username);
  }, [user.username, reloadAvatar]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    const firstInitial = data?.firstName?.charAt(0);
    const secondInitial = data?.lastName?.charAt(0);
    const avatarInitials = firstInitial + secondInitial;
    setUserInitials(avatarInitials);
  }, [data]);

  const signOutHandler = async () => {
    try {
      const language = localStorage.getItem("language"); // Store the language key
      await signOut();
      localStorage.clear();
      if (language) {
        localStorage.setItem("language", language); // Restore the language key
      }
      window.location.href = PATH_CONSTANTS.LOGIN;
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const isCustomer = userGroupPrefix === undefined;
  const isAdmin = userGroupPrefix === "Admin";
  const isBusAdmin = userGroupPrefix === "BusinessAdmin";

  if (adminPortalTranslationIsLoading) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error with Portal Admin Translations...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{ boxShadow: "none" }}>
        <Container className={themeStyles} maxWidth="xl">
          <Toolbar disableGutters style={{ maxHeight: "80px" }}>
            <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
              <Link to={PATH_CONSTANTS.HOME}>
                {logoLoaded && showLogo(customLogo)}
              </Link>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
              <Link to={PATH_CONSTANTS.HOME}>
                <img
                  src={CompanyLogoSmall}
                  alt="Scamnetic"
                  width="35px"
                  height="40px"
                />
              </Link>
            </Box>
            <Box
              sx={{ flexGrow: 1, display: "flex" }}
              justifyContent="flex-end"
            >
              {!isCustomer && (
                <>
                  {isAdmin && (
                    <>
                      <NavLink to={PATH_CONSTANTS.BUSINESS_LIST}>
                        Business Listings
                      </NavLink>
                      <NavLink to={PATH_CONSTANTS.PRODUCTS}>Products</NavLink>
                    </>
                  )}
                  {isBusAdmin && businessID && (
                    <>
                      <NavLink
                        state={{ selectedItem: data }}
                        to={`${PATH_CONSTANTS.BUSINESS_LIST}/${businessID}/${PATH_CONSTANTS.CUSTOMER_LIST}`}
                      >
                        {adminPortalTranslation.customer_list}
                      </NavLink>
                      <NavLink
                        state={{ selectedItem: data }}
                        to={`${PATH_CONSTANTS.BUSINESS_LIST}/${businessID}`}
                      >
                        {adminPortalTranslation["business_business_details"]}
                      </NavLink>
                    </>
                  )}
                  <NavLink to={PATH_CONSTANTS.REPORTS}>
                    {adminPortalTranslation.reports_header}
                  </NavLink>
                  {isAdmin && (
                    <NavLink to={PATH_CONSTANTS.WHITELIST}>
                      Domain Whitelist
                    </NavLink>
                  )}
                </>
              )}
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={adminPortalTranslation.hover_over_profile}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {loading ? (
                    <Avatar />
                  ) : (
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        padding: 3,
                        color: "#020D0F",
                        fontWeight: 700,
                      }}
                    >
                      {avatar ? (
                        <img
                          src={avatar}
                          alt="User Avatar"
                          style={{ maxWidth: "50px" }}
                        />
                      ) : (
                        userInitials
                      )}
                    </Avatar>
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  className="menuItem"
                  component={Link}
                  to={PATH_CONSTANTS.PROFILE}
                  onClick={handleCloseUserMenu}
                >
                  <Typography textAlign="center">
                    {adminPortalTranslation.menu_item_1}
                  </Typography>
                </MenuItem>
                <MenuItem component={"a"} onClick={signOutHandler}>
                  <Typography textAlign="center">
                    {adminPortalTranslation.menu_item_2}
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box className="scamPhone" bgcolor="#f2820a" padding={1}>
        <Typography
          variant="body1"
          color="white"
          textAlign="center"
          fontWeight={500}
        >
          {adminPortalTranslation.contact_message}
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default withAuthenticator(Navigation);
