import {createTheme} from "@mui/material/styles";

export const themeOptions = {
  palette: {
    mode: "light",
    secondary: {
      main: "#020d0f",
    },
    primary: {
      main: "#f2820a",
      contrastText: "rgba(255,255,255,0.87)",
    },
  },
};

export const THEME = createTheme({
  name: "my-theme",
  palette: {
    primary: {
      main: "#020D0F",
    },
    secondary: {
      main: "#f2820a",
      contrastText: "#ffffff",
    },
    tertiary: {
      main: "#D9D9D9",
      contrastText: "#000000",
    },
    error: {
      main: "#F20A0E",
    },
    default: {
      main: "#F20A0E",
    },
  },
  tokens: {
    colors: {
      primary: "#F2800A",
      orange: "#F2800A",
      bg: "#fff",
      text: "#333",
      grey: "#aaa",
    },
    fontSize: {
      small: "12px",
      medium: "16px",
      large: "24px",
    },

    components: {
      authenticator: {
        router: {
          boxShadow: `rgba(0, 0, 0, 0.1) 0px 0px 16px 0px`,
          borderWidth: "0",
        },
        form: {
          padding: `16px`,
        },
      },
      button: {
        primary: {
          backgroundColor: "#F2800A",
        },
        link: {
          color: "#F2800A",
        },
        link_hover: {
          color: "rgb(227, 80, 51)",
        },
        primary_hover: {
          backgroundColor: "#e35905",
        },
      },
      fieldcontrol: {
        _focus: {
          boxShadow: `0 0 0 2px rgba(227, 134, 51, 0.6)`,
        },
      },
      tab: {
        backgroundColor: "rgba(227, 80, 51)",
        item: { backgroundColor: "rgba(227, 80, 51)" },
      },
    },
  },
});

export const myTheme = createTheme({
  palette: {
    primary: {
      main: "#020D0F",
    },
    secondary: {
      main: "#F2800A",
    },
  },
});
